import { Fragment } from 'react'
import { Check, X } from 'react-feather'
import { toast } from 'react-toastify'
import Avatar from '@components/avatar'


const ToastComponent = ({ title, icon, color }) => (
    <Fragment>
      <div className='toastify-header pb-0'>
        <div className='title-wrapper'>
          <Avatar size='sm' color={color} icon={icon} />
          <h6 className='toast-title'>{title}</h6>
        </div>
      </div>
    </Fragment>
  )


export const sucessToast = (msg) => {
    toast.success(<ToastComponent title={msg} color='success' icon={<Check />} />, {
      icon: false,
      autoClose: 4100,
      hideProgressBar: true,
      closeButton: false
    })
  }

export const errorToast = (msg) => {
    toast.error(<ToastComponent title={`Error. ${msg}`} color='danger' icon={<X />} />, {
      icon: false,
      autoClose: 4100,
      hideProgressBar: true,
      closeButton: false
    })
  }

  export const sucessToastTime = (msg, time) => {
    toast.success(<ToastComponent title={msg} color='success' icon={<Check />} />, {
      icon: false,
      autoClose: time,
      hideProgressBar: true,
      closeButton: false
    })
  }

export const errorToastTime = (msg, time) => {
    toast.error(<ToastComponent title={`Error. ${msg}`} color='danger' icon={<X />} />, {
      icon: false,
      autoClose: time,
      hideProgressBar: true,
      closeButton: false
    })
  }