// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import todo from '@src/views/apps/todo/store'
import chat from '@src/views/apps/chat/store'
import users from '@src/views/apps/user/store'
import customers from  '@src/views/apps/customer/store'
import templates from  '@src/views/apps/template/store'
import campaigns from  '@src/views/apps/campaign/store'
import dashboard from  '@src/views/apps/dashboard/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'
import documents from '@src/views/apps/document/store'
import payments from '@src/views/apps/payment/store'
import newcampaigns from  '@src/views/apps/create-campaign/store'
import bankaccounts from '@src/views/apps/bankaccount/store'
import subscriptions from '@src/views/apps/suscription/store'
import plancampaigns from '@src/views/apps/plan-campaign/store'
import newplancampaigns from '@src/views/apps/create-plan-campaign/store'
import inboxmessages from '@src/views/apps/inbox-messages/store'
import paymentsGateway from '../views/apps/payment-gateway/store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  customers,
  templates,
  campaigns,
  dashboard,
  documents,
  payments,
  paymentsGateway,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  newcampaigns,
  bankaccounts,
  subscriptions,
  plancampaigns,
  newplancampaigns,
  inboxmessages
}

export default rootReducer