// ** Redux Imports
import React from 'react'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
import { toast } from 'react-toastify'
// ** Axios Imports
import axios from 'axios'
// import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'
// const qs = require('qs')
export const getAllData = createAsyncThunk('appDocuments/getAllData', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 15000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/customers/documents/`, {params: {page_size: params.page_size, p:params.page, idCustomer:params.idCustomer, id:params.document, status:params.status, start:params.start, end:params.end}})
  return response
})

export const getDocumentsContifico = createAsyncThunk('appDocuments/getDocumentsContifico', async(props, {dispatch, getState}) => {
  const state = getState()
  const instancePost = axios.create({
    baseURL: urls.url,
    timeout: -1,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })

  const promise = async () => {
    try {
      await instancePost.post("/customers/documents/sync_documents_with_contifico/", {})
      dispatch(getAllData({ page: 1, idCustomer: "", document: "", status: "", start: "", end: "" }))
    } catch (err) {
      console.error(err)
    }
  }
  // instancePost.request({
  //   method: "post", 
  //   url: "/customers/documents/sync_documents_with_contifico/", 
  //   data: {},
  //   onUploadProgress: p => {
  //     const progress = p.loaded / p.total

  //     // check if we already displayed a toast
  //     if (props.toastId.current === null) {
  //       props.toastId.current = toast('Upload in Progress', { progress })
  //     } else {
  //       toast.update(props.toastId.current, { progress })
  //     }
  //   }
  // }).then(data => {
  
  //   toast.done(props.toastId.current)
  //   console.log(data)
  //   dispatch(getAllData({ page: 1, idCustomer: "", document: "", status: "", start: "", end: "" }))
  // })
  if (!state.loading) {
    await toast.promise(promise, {
      pending: 'Sincronizando documentos',
      loading: 'Sincronizando documentos',
      success: 'Documentos sincronizados',
      error: 'Ha ocurrido un error'
    })
  }
  
})

export const setIsFetchingResults = createAsyncThunk('appDocuments/setIsFetchingResults', () => {
  return "fetching"
})

export const cleanDocuments = createAsyncThunk('appDocuments/cleanContacts', () => {
  return "clean"
})

export const appDocumentsSlice = createSlice({
  name: 'appDocuments',
  initialState: {
    allData: [],
    total: 1,
    loading: false,
    isFetching: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data.results
        state.total = action.payload.data.count
        state.isFetching = false
      })

      .addCase(getDocumentsContifico.pending, (state) => {
        state.loading = true
      })
      .addCase(setIsFetchingResults.fulfilled, (state) => {
        state.isFetching = true
      })
      .addCase(getDocumentsContifico.fulfilled, (state) => {
        state.loading = false
      })
  
      .addCase(cleanDocuments.fulfilled, (state) => {
        state.allData = []
        state.total = 1
      })

  }
})

export default appDocumentsSlice.reducer
