// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'

//const qs = require('qs')

export const getAllTemplates = createAsyncThunk('appCampaigns/getAllTemplates', async (params) => {
  const instanceTemplate = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceTemplate.get(`/customers/templates_all/`, {params: {search:params.channel, status: params.status, is_global: params.is_global}})
  return response
})

export const getTemplate = createAsyncThunk('appTemplates/getTemplate', async (params) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instance.get(`/customers/templates/${params.id}/`, {params: {is_global: params.is_global}})
  return response.data
})

export const cleanTemplate = createAsyncThunk('appTemplates/cleanTemplate', () => {
  return "template"
})

export const getAllCustomers = createAsyncThunk('appCampaigns/getAllCustomers', async (source) => {
  const instanceTemplate = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    cancelToken: source.token,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceTemplate.post(`/customers/customers/groupby_document/`)
  return response
})

export const addCampaignFrom = createAsyncThunk('appCampaigns/addCampaignFrom', async (params) => {
  const instanceAddCampaignFrom = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  instanceAddCampaignFrom.post('/campaigns/campaigns/create_from/', params.campaign)
    .then(async resp => {
      sucessToast("Campaña lanzada correctamente")
      return resp.data
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        errorToast(err.response.data.msg)
      } else {
        errorToast("Campaña no fue lanzada correctamente")
      }
    })
})

const concatArraysLikeJson = (a, b) => {
  const result = a.map((e) => {
    let customer__id = ""
    let customer__first_name = ""
    let customer__last_name = ""
    let customer__dni = ""
    let customer__tradename = ""
    let customer__position = ""
    let jphone = ""
    let jemail = ""
    let jsaldo = 0
    let jdocs = 0
    let nContacts = 0
    for (let j = 0; j < b.length; j++) {
      if (e.customer__id === b[j].customer__id) {
        if (nContacts === 0) {
          customer__id = e.customer__id
          customer__first_name = e.customer__first_name
          customer__last_name = e.customer__last_name
          customer__dni = e.customer__dni
          customer__tradename = e.customer__tradename
          customer__position = e.customer__position
          jphone = b[j].phone
          jemail = b[j].email,
          jsaldo = e.saldo
          jdocs = e.docs
          nContacts = nContacts + 1
        } else if (nContacts > 0) {
          nContacts = nContacts + 1
        }
      }
      
    }
    if (nContacts > 0) {
      return {
        id : customer__id,
        first_name: customer__first_name,
        last_name: customer__last_name,
        dni: customer__dni,
        tradename: customer__tradename,
        position: customer__position,
        phone: jphone,
        email: jemail,
        saldo: jsaldo,
        docs: jdocs,
        contactos: nContacts
      }
    } else {
      return {
        id : e.customer__id,
        first_name: e.customer__first_name,
        last_name: e.customer__last_name,
        dni: e.customer__dni,
        tradename: e.customer__tradename,
        position: e.customer__position,
        phone: "Sin celular",
        email: "Sin email",
        saldo: e.saldo,
        docs: e.docs,
        contactos: 0
        } 
      }
    })
  return result
}

export const appCreateCampaignSlice = createSlice({
  name: 'appCreateCampaign',
  initialState: {
    allTemplates: [],
    allCustomers: [],
    allContacts: [],
    allCustomersByDocuments: [],
    total: 1,
    selectedTemplate: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.allTemplates = action.payload.data
      })

      .addCase(cleanTemplate.fulfilled, (state) => {
        state.selectedTemplate = null
        state.total = 1
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.selectedTemplate = action.payload
      })

      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.allCustomers = action.payload.data.infoDocs
        state.allContacts = action.payload.data.contactos
        state.allCustomersByDocuments = 
          concatArraysLikeJson(state.allCustomers, state.allContacts)
      })

  }
})

export default appCreateCampaignSlice.reducer
