// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'

// ** Axios Imports
import axios from 'axios'

export const fetchEvents = createAsyncThunk('appNewPlanCampaign/fetchEvents', async calendars => {
  const response = await axios.get('/apps/calendar/events', { calendars })
  return response.data
})

export const fetchEventById = async (id) => {
  const calendars = ["WhatsApp", "Email"]
  const response = await axios.get('/apps/calendar/events', { calendars })
  return response.data.filter(elem => elem.id === id)[0]
}

export const addCampaign = createAsyncThunk('appNewPlanCampaign/addCampaign', async (event, { dispatch, getState }) => {
  await axios.post('/apps/calendar/add-event', { event })
  await dispatch(fetchEvents(getState().newplancampaigns.selectedCalendars))
  return event
})

export const addCampaignsByArray = createAsyncThunk('appNewPlanCampaign/addCampaignsByArray', async (events, { dispatch, getState }) => {
  await axios.post('/apps/calendar/add-events-by-array', { events })
  const response = await dispatch(fetchEvents(getState().newplancampaigns.selectedCalendars))
  return response.payload
})

export const updateEvent = createAsyncThunk('appNewPlanCampaign/updateEvent', async (event, { dispatch, getState }) => {
  await axios.post('/apps/calendar/update-event', { event })
  await dispatch(fetchEvents(getState().newplancampaigns.selectedCalendars))
  return event
})

export const updateFilter = createAsyncThunk('appNewPlanCampaign/updateFilter', async (filter, { dispatch, getState }) => {
  const calendars = getState().newplancampaigns.selectedCalendars
  if (calendars.includes(filter)) {
    await dispatch(fetchEvents(calendars.filter(i => i !== filter)))
  } else {
    await dispatch(fetchEvents([...calendars, filter]))
  }
  return filter
})

export const updateAllFilters = createAsyncThunk('appNewPlanCampaign/updateAllFilters', async (value, { dispatch }) => {
  if (value === true) {
    await dispatch(fetchEvents(['WhatsApp', 'Email']))
  } else {
    await dispatch(fetchEvents([]))
  }
  return value
})

export const removeEvent = createAsyncThunk('appNewPlanCampaign/removeEvent', async id => {
  await axios.delete('/apps/calendar/remove-event', { id })
  return id
})

export const getAllTemplates = createAsyncThunk('appNewPlanCampaign/getAllTemplates', async (params) => {
  const instanceTemplate = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceTemplate.get(`/customers/templates_all/`, {params: {search:params.channel, status: params.status, is_global: params.is_global}})
  return response
})

export const getTemplate = createAsyncThunk('appNewPlanCampaign/getTemplate', async (params) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instance.get(`/customers/templates/${params.id}/`, {params: {is_global: params.is_global}})
  return response.data
})

export const getAllCustomers = createAsyncThunk('appNewPlanCampaign/getAllCustomers', async () => {
  const instanceTemplate = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceTemplate.post(`/customers/customers/groupby_document/`)
  return response
})

export const createScheduledCampaigns = createAsyncThunk('appNewPlanCampaign/createScheduledCampaigns', async ({events, num_docs}) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instance.post(`/campaigns/timeslots/create_from_array/`, {events, num_docs})
  return response.data
})

export const cleanTemplate = createAsyncThunk('appNewPlanCampaign/cleanTemplate', () => {
  return "template"
})
export const cleanAllTemplates = createAsyncThunk('appNewPlanCampaign/cleanAllTemplates', () => {
  return "template"
})
export const cleanEvents = createAsyncThunk('appNewPlanCampaign/cleanEvents', async() => {
  const response = await axios.post('/apps/calendar/clear-events')
  return response
})

const concatArraysLikeJson = (a, b) => {
  const result = a.map((e) => {
    let customer__id = ""
    let customer__first_name = ""
    let customer__last_name = ""
    let customer__dni = ""
    let customer__tradename = ""
    let customer__position = ""
    let jphone = ""
    let jemail = ""
    let jsaldo = 0
    let jdocs = 0
    let nContacts = 0
    for (let j = 0; j < b.length; j++) {
      if (e.customer__id === b[j].customer__id) {
        if (nContacts === 0) {
          customer__id = e.customer__id
          customer__first_name = e.customer__first_name
          customer__last_name = e.customer__last_name
          customer__dni = e.customer__dni
          customer__tradename = e.customer__tradename
          customer__position = e.customer__position
          jphone = b[j].phone
          jemail = b[j].email,
          jsaldo = e.saldo
          jdocs = e.docs
          nContacts = nContacts + 1
        } else if (nContacts > 0) {
          nContacts = nContacts + 1
        }
      }
      
    }
    if (nContacts > 0) {
      return {
        id : customer__id,
        first_name: customer__first_name,
        last_name: customer__last_name,
        dni: customer__dni,
        tradename: customer__tradename,
        position: customer__position,
        phone: jphone,
        email: jemail,
        saldo: jsaldo,
        docs: jdocs,
        contactos: nContacts
      }
    } else {
      return {
        id : e.customer__id,
        first_name: e.customer__first_name,
        last_name: e.customer__last_name,
        dni: e.customer__dni,
        tradename: e.customer__tradename,
        position: e.customer__position,
        phone: "Sin celular",
        email: "Sin email",
        saldo: e.saldo,
        docs: e.docs,
        contactos: 0
        } 
      }
    })
  return result
}

export const appNewPlanCampaignSlice = createSlice({
  name: 'appNewPlanCampaign',
  initialState: {
    events: [],
    totalEvents: 0,
    allTemplates: [],
    selectedEvent: {},
    selectedTemplate: {},
    allCustomersByDocument: [],
    selectedCalendars: ['WhatsApp', 'Email']
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload
      })
      .addCase(updateFilter.fulfilled, (state, action) => {
        if (state.selectedCalendars.includes(action.payload)) {
          state.selectedCalendars.splice(state.selectedCalendars.indexOf(action.payload), 1)
        } else {
          state.selectedCalendars.push(action.payload)
        }
      })
      .addCase(addCampaign.fulfilled, (state) => {
        state.totalEvents += 1
      })
      .addCase(addCampaignsByArray.fulfilled, (state, action) => {
        state.events = action.payload
        state.totalEvents = action.payload.length
      })
      .addCase(removeEvent.fulfilled, (state) => {
        state.totalEvents -= 1
      })
      .addCase(updateAllFilters.fulfilled, (state, action) => {
        const value = action.payload
        let selected = []
        if (value === true) {
          selected = ['WhatsApp', 'Email']
        } else {
          selected = []
        }
        state.selectedCalendars = selected
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.allTemplates = action.payload.data
      })
      .addCase(getTemplate.fulfilled, (state, action) => {
        state.selectedTemplate = action.payload
      })
      .addCase(getAllCustomers.fulfilled, (state, action) => {
        state.allCustomersByDocument = 
          concatArraysLikeJson(action.payload.data.infoDocs, action.payload.data.contactos)
      })
      .addCase(cleanTemplate.fulfilled, (state) => {
        state.selectedTemplate = null
      })
      .addCase(cleanAllTemplates.fulfilled, (state) => {
        state.allTemplates = null
      })
      .addCase(cleanEvents.fulfilled, (state) => {
        state.events = []
        state.totalEvents = 0
        state.selectedCalendars = ['WhatsApp', 'Email']
      })
  }
})

export const { selectEvent } = appNewPlanCampaignSlice.actions

export default appNewPlanCampaignSlice.reducer
