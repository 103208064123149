import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
import axios from 'axios'

export const getAllData = createAsyncThunk('appPaymentsGateway/getAllData', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`integrations/paylinks/get_trackable_transactions/`, {params: {p:params.page, profile_id:params.profile_id, idCustomer:params.idCustomer, start:params.start, end:params.end, status: params.status}})
  return response
})

export const getPaymentGateway = createAsyncThunk('appPaymentsGateway/getPaymentGateway', async(id) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`integrations/paylinks/${id}/`)
  return response
})

export const setIsFetchingResults = createAsyncThunk('appPaymentsGateway/setIsFetchingResults', () => {
  return "fetching"
})

export const appPaymentsGatewaySlice = createSlice({
  name: 'appPaymentsGateway',
  initialState: {
    allData: [],
    total: 1,
    selectedPayment: null,
    isFetching: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        const results = action.payload.data
        state.allData = results.results
        state.total = results.count
        state.isFetching = false
      })
      .addCase(getPaymentGateway.fulfilled, (state, action) => {
        state.selectedPayment = action.payload.data
        state.isFetching = false
      })
      .addCase(setIsFetchingResults.fulfilled, (state) => {
        state.isFetching = true
      })
  }
})

export default appPaymentsGatewaySlice.reducer