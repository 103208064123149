// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
// import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'

// const qs = require('qs')

export const getAllData = createAsyncThunk('appPlanCampaign/getAllData', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/campaigns/timeslots/`, {params: {p:params.page, search:params.query}})
  return response
})

// export const updateBankAccount = createAsyncThunk('appCustomers/updateCustomer', (params, { dispatch }) => {
//   const instance2 = axios.create({
//     baseURL: urls.url,
//     timeout: 5000,
//     headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
//   })
//   instance2.put(`/customers/bankaccounts/${params.bankaccount.id}/`, qs.stringify(params.bankaccount))
//     .then(async resp => {
//       await dispatch(getAllData({page:1, query:""}))
//       sucessToast("La cuenta bancaria se modificó correctamente")
//       return resp.data
//     })
//     .catch(() => {
//       errorToast("La cuenta bancaria no se modificó correctamente")
//     })
// })

export const deletePlanCampaign = createAsyncThunk('appPlanCampaign/deletePlanCampaign', async (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  await instance2.delete(`/campaigns/timeslots/${params.id}/`)
  await dispatch(getAllData(params.getData))
  return params.id
})

export const appPlanCampaignSlice = createSlice({
  name: 'appPlanCampaign',
  initialState: {
    allData: [],
    fragments: [],
    total: 0,
    selectedPlanCampaign: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.data.length

        const newDataTable = {}
        state.allData.forEach(elem => {
          const launch = new Date(elem.launch_date)
          const launch_month = launch.getMonth()
          const launch_year = launch.getFullYear()
          const key = `${launch_month}|${launch_year}|${elem.no_documentos}`
          const camp_month = newDataTable[key]
          if (!!camp_month) {
            newDataTable[key] = [elem, ...camp_month] 
          } else {
            newDataTable[key] = [elem]
          }
        })

        const newFragments = []

        for (const [key, value] of Object.entries(newDataTable)) {
          const newFragment = {key, value}
          newFragments.push(newFragment)
        }

        state.fragments = newFragments
      })

  }
})

export default appPlanCampaignSlice.reducer