/*eslint-disable */
import mock from '../mock'

const date = new Date()

const prevDay = new Date().getDate() - 1
const nextDay = new Date(new Date().getTime() + 24 * 60 * 60 * 1000)

// prettier-ignore
const nextMonth = date.getMonth() === 11 ? new Date(date.getFullYear() + 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1)
// prettier-ignore
const prevMonth = date.getMonth() === 11 ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear(), date.getMonth() - 1, 1)

const data = {
  events: []
}

// ------------------------------------------------
// GET: Return calendar events
// ------------------------------------------------
mock.onGet('/apps/calendar/events').reply(config => {
  // Get requested calendars as Array
  const calendars = config.calendars
  return [200, data.events.filter(event => calendars.includes(event.extendedProps.calendar))]
})

// ------------------------------------------------
// POST: Add new event
// ------------------------------------------------
mock.onPost('/apps/calendar/add-event').reply(config => {
  // Get event from post data
  const { event } = JSON.parse(config.data)

  const { length } = data.events
  let lastIndex = 0
  if (length) {
    lastIndex = data.events[length - 1].id
  }
  event.id = lastIndex + 1

  data.events.push(event)

  return [201, { event }]
})

// ------------------------------------------------
// POST: Clear events
// ------------------------------------------------
mock.onPost('/apps/calendar/clear-events').reply(() => {
  // Get event from post data
  data.events.splice(0)

  return [200]
})

// ------------------------------------------------
// POST: Update Event
// ------------------------------------------------
mock.onPost('/apps/calendar/update-event').reply(config => {
  const { event: eventData } = JSON.parse(config.data)

  // Convert Id to number
  eventData.id = Number(eventData.id)
  const event = data.events.find(ev => ev.id === Number(eventData.id))

  const end = eventData.start
  const new_eventData = {...eventData, end}
  Object.assign(event, new_eventData)

  return [200, { event }]
})

// ------------------------------------------------
// POST: Add events by array
// ------------------------------------------------
mock.onPost('/apps/calendar/add-events-by-array').reply(config => {
  const { events } = JSON.parse(config.data)

  const type_campaigns = {'WhatsApp': 0, 'Email': 1}

  events.forEach(event => {
    const new_event = {
      id:       event.id,
      title:    event.template.name,
      allDay:   false,
      display:  'block',
      start:    new Date(event.launch_date),
      end:      new Date(event.launch_date),
      extendedProps: {
        calendar:         event.campaign_type,
        status:           event.status,
        type_campaign:    {value: type_campaigns[event.campaign_type], label: event.campaign_type},
        template_id:      {value: event.template.id, label: event.template.name},
        already_created:  true
      }
    }

    data.events.push(new_event)
  })

  return [200, data.events]
})


// ------------------------------------------------
// DELETE: Remove Event
// ------------------------------------------------
mock.onDelete('/apps/calendar/remove-event').reply(config => {
  // Get event id from URL
  let { id } = config

  // Convert Id to number
  const eventId = Number(id)

  const eventIndex = data.events.findIndex(ev => ev.id === eventId)
  data.events.splice(eventIndex, 1)
  return [200]
})
