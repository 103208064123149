// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'

export const getDueWallet = createAsyncThunk('appDashboard/getDueWallet', async () => {
    const instanceCampaign = axios.create({
        baseURL: urls.url,
        timeout: 5000,
        headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
    })
    const response = await instanceCampaign.post(`/customers/customers/pendingValuesDocs_byCustomer/`)
    return response.data
})

export const cleanDashboard = createAsyncThunk('appDashboard/cleanDashboard', () => {
    return "clean"
})


export const appDashboardSlice = createSlice({
    name: 'appDashboard',
    initialState: {
        dueWallet: [],
        dueWalletDocuments: [],
        dueWalletValues: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
        .addCase(getDueWallet.fulfilled, (state, action) => {
            state.dueWallet = []
            state.dueWalletDocuments = []
            state.dueWalletValues = []
            state.dueWallet = Object.keys(action.payload)
            Object.values(action.payload).forEach(
                element => {
                    state.dueWalletDocuments.push(element.cantidad)
                    state.dueWalletValues.push(Math.round(element.total))
                }
            )
        })

        .addCase(cleanDashboard.fulfilled, (state) => {
            state.dueWallet = []
            state.dueWalletDocuments = []
            state.dueWalletValues = []
        })
    }
})

export default appDashboardSlice.reducer
