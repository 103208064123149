// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'

const qs = require('qs')

export const getAllData = createAsyncThunk('appSubscriptions/getAllData', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/customers/subscriptions/`, {params: {p:params.page, search:params.query}})
  return response
})

export const getCustomers = createAsyncThunk('appSubscriptions/getCustomers', async (id) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/customers/getAllCustomersNoPag/${id}`)
  return response
})

export const getSubscription = createAsyncThunk('appSubscriptions/getSubscription', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  const response = await instance.get(`/customers/subscriptions/${id}/`)
  return response.data
})

export const createSubscription = createAsyncThunk('appSubscriptions/createSubscription', async (params, { dispatch }) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  instance.post('/customers/subscriptions/', params.data)
    .then(async resp => {
      await dispatch(getAllData({page:1, query:""}))
      sucessToast("Suscripción creada correctamente")
      return resp.data
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        errorToast(err.response.data.msg)
      } else {
        errorToast("No se pudo crear la suscripción.")
      }
    })
  
})

export const getDocumentsBySubscription = createAsyncThunk('appSubscriptions/getDocumentsBySubscription', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instance.get(`/customers/documents_by_subscription/${id}`)
  return response.data
})

export const updateSubscription = createAsyncThunk('appSubscriptions/updateSubscription', (params, { dispatch }) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })

  instance.post(`/customers/subscriptions/update_subscription/`, qs.stringify(params.subscription))
    .then(async resp => {
      await dispatch(getSubscription(params.subscription.id))
      sucessToast("Suscripción se modificó correctamente")
      return resp.data
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        errorToast(err.response.data.msg)
      } else {
        errorToast("No se pudo modificar la suscripción")
      }
    })
})

export const appSubscriptionsSlice = createSlice({
  name: 'appSubscriptions',
  initialState: {
    allData: [],
    customers: [],
    selectedSubscription: null,
    documents: [],
    total: 0,
    totalDocuments: 0
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data.results
        state.total = action.payload.data.count
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customers = action.payload.data
      })
      .addCase(getSubscription.fulfilled, (state, action) => {
        state.selectedSubscription = action.payload
      })
      .addCase(getDocumentsBySubscription.fulfilled, (state, action) => {
        state.documents = action.payload
        state.totalDocuments = action.payload.length
      })

  }
})

export default appSubscriptionsSlice.reducer
