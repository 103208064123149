// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'

const qs = require('qs')

export const getAllCampaigns = createAsyncThunk('appCampaigns/getAllCampaigns', async (params) => {
  const instanceCampaign = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceCampaign.get(`/campaigns/campaigns_list/`, {params: {p:params.page, search:params.query, template: params.template}})
  return response
})

export const getAllCustomersByCampaign = createAsyncThunk('appCampaigns/getAllCustomersByCampaign', async (params) => {
  const instanceAllCustomersByCampaign = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceAllCustomersByCampaign.get(`/customers/customerCampaign/${params.idCampaign}`, {params: {p:params.page, search:params.query}})
  return response
})

export const getAllEventsByCampaign = createAsyncThunk('appCampaigns/getAllEventsByCampaign', async (params) => {
  const instanceAllEventsByCampaign = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceAllEventsByCampaign.get(`/campaigns/campaignEvents/`, {params: {p:params.page, search:params.query, idCampaign:params.idCampaign, profile_id:params.profile_id}})
  return response
})

export const getStatiticsByCampaign = createAsyncThunk('appCampaigns/getStatiticsByCampaign', async (params) => {
  const getStatiticsByCampaign = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {"Content-Type": "application/json"}
  })
  const response = await getStatiticsByCampaign.get(`/campaigns/statiticsCampaign/${params.idCampaign}`)
  return response
})

export const getAllTemplates = createAsyncThunk('appCampaigns/getAllTemplates', async () => {
  const instanceTemplate = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceTemplate.get(`/customers/templates_all/`)
  return response
})

export const cleanCampaign = createAsyncThunk('appCampaigns/cleanCampaign', () => {
  return "clean"
})

export const getAllGroups = createAsyncThunk('appCampaigns/getAllGroups', async () => {
  const instanceAllGroups = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceAllGroups.get(`/customers/groups_all/`)
  return response
})

export const getCampaign = createAsyncThunk('appCampaigns/getCampaign', async id => {
  const instanceCampaign = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceCampaign.get(`/campaigns/campaigns/${id}/`)
  return response.data
})

export const addCampaign = createAsyncThunk('appCampaigns/addCampaign', async (params, { dispatch }) => {
  const instanceAddCampaign = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  instanceAddCampaign.post('/campaigns/campaigns/', params.campaign)
    .then(async resp => {
      console.log(resp)
      await dispatch(getAllCampaigns({page:1, query:""}))
      sucessToast("Campaña creada correctamente")
      return resp.data
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        errorToast(err.response.data.msg)
      } else {
        errorToast("Campaña no fue creado correctamente")
      }
    })
})

export const updateCampaign = createAsyncThunk('appCampaigns/updateCampaign', (params, { dispatch }) => {
  const instanceUpdateCampaign = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instanceUpdateCampaign.put(`/campaigns/campaigns/${params.campaign.id}/`, qs.stringify(params.campaign))
  .then(async resp => {
    await dispatch(getCampaign(params.campaign.id))
    sucessToast("Campaña se modifico correctamente")
    return resp.data
  })
  .catch(() => {
    errorToast("Campaña no se modifico correctamente")
  })
})


export const deleteCampaign = createAsyncThunk('appCampaigns/deleteCampaign', async (id, { dispatch}) => {
  await axios.delete(`/campaigns/campaigns/${id}/`)
  await dispatch(getAllCampaigns({page:1, query:""}))
  return id
})

export const appCampaignsSlice = createSlice({
  name: 'appCampaigns',
  initialState: {
    allCampaigns: [],
    allGroups: [],
    allTemplates: [],
    allCustomersByCampaign: [],
    allEventsByCampaign: [],
    statiticsByCampaign: [],
    totalCustomersByCampaign: 1,
    totalEventsByCampaign: 1,
    total: 1,
    selectedCampaign: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllCampaigns.fulfilled, (state, action) => {
        state.allCampaigns = action.payload.data.results
        state.total = action.payload.data.count
      })
      .addCase(getAllCustomersByCampaign.fulfilled, (state, action) => {
        state.allCustomersByCampaign = action.payload.data.results
        state.totalCustomersByCampaign = action.payload.data.count
      })
      .addCase(getAllEventsByCampaign.fulfilled, (state, action) => {
        state.allEventsByCampaign = action.payload.data.results
        state.totalEventsByCampaign = action.payload.data.count
      })
      .addCase(getStatiticsByCampaign.fulfilled, (state, action) => {
        state.statiticsByCampaign = action.payload.data
      })
      .addCase(getAllTemplates.fulfilled, (state, action) => {
        state.allTemplates = action.payload.data
      })
      .addCase(getAllGroups.fulfilled, (state, action) => {
        state.allGroups = action.payload.data
        console.log("Grupo", state.allGroups)
      })

      .addCase(cleanCampaign.fulfilled, (state) => {        
        state.allGroups = []
        state.allCampaigns = [],
        state.allTemplates = [],
        state.allCustomersByCampaign = [],
        state.allEventsByCampaign = [],
        state.statiticsByCampaign = [],
        state.totalCustomersByCampaign = 1,
        state.totalEventsByCampaign = 1,
        state.total = 1,
        state.selectedCampaign = null
      })

      .addCase(getCampaign.fulfilled, (state, action) => {
        state.selectedCampaign = action.payload
      })

  }
})

export default appCampaignsSlice.reducer
