import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
import axios from 'axios'

export const getAllData = createAsyncThunk('appInboxMessages/getAllData', async(params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  return await instanceGet.get(`/campaigns/campaignEvents/`, {params: {p:params.page, profile_id:params.profile_id, idCustomer:params.idCustomer, status:params.status, start:params.start, end:params.end, number:params.number, email:params.email}})
})

export const getOptionsClients = createAsyncThunk('appInboxMessages/getOptionsClients', async(params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  return await instanceGet.get(`/customers/customers/list_client/`, {params:params.name})
})

export const cleanInboxMessages = createAsyncThunk('appInboxMessages/cleanInboxMessages', () => {
  return "clean"
})

export const setIsFetchingResults = createAsyncThunk('appInboxMessages/setIsFetchingResults', () => {
  return "fetching"
})

const appInboxMessagesSlice = createSlice({
  name: 'appInboxMessages',
  initialState: {
    allData: [],
    total: 0,
    optionsClients: [],
    isFetching: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data.results
        state.total = action.payload.data.count
        state.isFetching = false
      })
      .addCase(getOptionsClients.fulfilled, (state, action) => {
        state.optionsClients = action.payload.data
        state.isFetching = false
      })
      .addCase(setIsFetchingResults.fulfilled, (state) => {
        state.isFetching = true
      })
      .addCase(cleanInboxMessages.fulfilled, (state) => {
        state.optionsClients = []
        state.allData = []
        state.total = 0
      })
  }
})

export default appInboxMessagesSlice.reducer