// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import urls from  '@src/@core/auth/jwt/urls'
// ** Axios Imports
import axios from 'axios'
import { errorToast, sucessToast } from '../../../components/messageToast/messageToast'

const qs = require('qs')

export const getAllData = createAsyncThunk('appBankAccount/getAllData', async (params) => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/customers/bankaccounts/`, {params: {p:params.page, search:params.query}})
  return response
})

export const getBanks = createAsyncThunk('appBankAccount/getBanks', async() => {
  const instanceGet = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  const response = await instanceGet.get(`/customers/banks/`)
  return response
})

export const getBankAccount = createAsyncThunk('appBankAccount/getBankAccount', async id => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  const response = await instance.get(`/customers/bankaccounts/${id}/`)
  return response.data
})

export const addBankAccount = createAsyncThunk('appBankAccount/addBankAccount', async (params, { dispatch }) => {
  const instance = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/json"}
  })
  
  instance.post('/customers/bankaccounts/', params.data)
    .then(async resp => {
      await dispatch(getAllData({page:1, query:""}))
      sucessToast("Cuenta bancaria añadida correctamente")
      return resp.data
    })
    .catch((err) => {
      if (err?.response?.data?.error) {
        errorToast(err.response.data.msg)
      } else {
        errorToast("Error en guardar cuenta bancaria")
      }
    })
  
})

export const updateBankAccount = createAsyncThunk('appCustomers/updateBankAccount', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instance2.put(`/customers/bankaccounts/${params.bankaccount.id}/`, qs.stringify(params.bankaccount))
    .then(async resp => {
      await dispatch(getAllData({page:1, query:""}))
      sucessToast("La cuenta bancaria se modificó correctamente")
      return resp.data
    })
    .catch(() => {
      errorToast("La cuenta bancaria no se modificó correctamente")
    })
})

export const updateBankAccountWithoutToast = createAsyncThunk('appBankAccount/updateBanAccountWithoutToast', (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  instance2.put(`/customers/bankaccounts/${params.bankaccount.id}/`, qs.stringify(params.bankaccount))
    .then(async resp => {
      await dispatch(getAllData(params.getData))
      return resp.data
    })
    .catch(() => {
      errorToast("Cuenta bancaria no se modificó correctamente")
    })
})

export const deleteBankAccount = createAsyncThunk('appBankAccount/deleteBankAccount', async (params, { dispatch }) => {
  const instance2 = axios.create({
    baseURL: urls.url,
    timeout: 5000,
    headers: {Authorization: "Bearer " + localStorage.getItem("token"), "Content-Type": "application/x-www-form-urlencoded"}
  })
  await instance2.delete(`/customers/bankaccounts/${params.id}/`)
  await dispatch(getAllData(params.getData))
  return params.id
})

export const appBankAccountSlice = createSlice({
  name: 'appBankAccount',
  initialState: {
    allData: [],
    total: 1,
    selectedBankAccount: null
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getAllData.fulfilled, (state, action) => {
        state.allData = action.payload.data
        state.total = action.payload.data.length
      })
      .addCase(getBankAccount.fulfilled, (state, action) => {
        state.selectedBankAccount = action.payload
      })
  }
})

export default appBankAccountSlice.reducer
